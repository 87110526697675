<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="container">
      <div class="wrapper">
        <div class="content-wrapper">
          <div class="card-body">
            <div class="row">
              <div class="col-md-5"></div>
              <div class="col-md-3">
                <strong>
                  GUIA VENTAS A TERCEROS
                </strong>
              </div>
              <div class="col-md-2 float-right">
                <h6>
                  <span class="badge badge-success" v-if="estadoGuia == 1"
                    >Estado: Disponible</span
                  >
                  <span class="badge badge-primary" v-if="estadoGuia == 2"
                    >Estado: Usada</span
                  >
                  <span class="badge badge-warning" v-if="estadoGuia == 6"
                    >Estado: Sin Imprimir</span
                  >
                </h6>
              </div>
              <div class="col-md-2" v-if="accion == 'Cambiar'">
                <span class="badge badge-warning"
                  >N° GUIA ANTERIOR:<br />
                  {{ form.guia.numero }}-{{
                    form.guia.digito_verificacion
                  }}</span
                >
              </div>
            </div>
            <div class="row mt-3 mb-2">
              <!-- Check Tercero -->
              <div class="col-md-2 text-center">
                <label for="check_tercero">TERCERO</label>
                <input
                  v-model="tercero"
                  type="checkbox"
                  value="1"
                  class="form-control form-control-sm"
                  @change="buscarGuiaInput()"
                  :disabled="form.acto === 4"
                />
              </div>
              <!-- Número Guía -->
              <div class="col-md-4" v-if="accion != 'Cambiar'">
                <label for="guia">N° GUIA</label>
                <input
                  type="number"
                  v-model="form.numero_guia"
                  style="color: #ff0000; font-weight: bold"
                  class="form-control form-control-sm"
                  disabled
                  :class="
                    $v.form.numero_guia.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
              </div>
              <div class="col-md-4" v-else>
                <label for="guia">N° GUIA</label>
                <v-select
                  v-model="guia"
                  placeholder="Guia"
                  label="numero"
                  :options="listasForms.guias"
                  @input="selectGuia()"
                  class="form-control form-control-sm p-0"
                  :disabled="accion == 'Show'"
                  :class="[
                    $v.guia.$invalid ? 'is-invalid' : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                ></v-select>
              </div>
              <!-- Digito Verificación -->
              <div class="col-md-2">
                <label for="digito_verificacion">DV</label>
                <input
                  v-model="digito_verificacion"
                  style="color: #ff0000; font-weight: bold"
                  class="form-control form-control-sm"
                  :min="0"
                  :max="9"
                  :class="
                    $v.digito_verificacion.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  @input="selectDv()"
                />
                <div class="error" v-if="!form.numero_guia">
                  <span class="badge bg-danger">Digite Número Guía</span>
                </div>
              </div>
              <!-- Tipo Producto -->
              <div class="col-md-4">
                <label for="producto">TIPO PRODUCTO</label>
                <v-select
                  v-model="tipo_producto"
                  class="form-control form-control-sm p-0"
                  placeholder="Nombre"
                  label="nombre"
                  :options="listasForms.tipo_productos"
                  @input="selectTipoProducto()"
                  :class="[
                    $v.form.tipo_producto_id.$invalid
                      ? 'is-invalid'
                      : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                ></v-select>
              </div>
            </div>
            <div class="small-box bg-light p-2 mt-2">
              <div class="row" style="font-size: 12px">
                <!-- Lugar Expedición -->
                <div class="form-group col-sm-6">
                  <label for="fecha_expedicion">LUGAR DE EXPEDICION</label>
                  <v-select
                    v-model="ciudad_origen"
                    class="form-control form-control-sm p-0"
                    placeholder="Nombre"
                    label="nombre"
                    :options="listasForms.ciudades"
                    @input="selectCiudadOrigen()"
                    :class="[
                      $v.form.ciudad_origen_id.$invalid
                        ? 'is-invalid'
                        : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    disabled
                  ></v-select>
                </div>
                <!-- Fecha Hora Expedición -->
                <div class="form-group col-md-6">
                  <label for="fecha_expedicion1"
                    >FECHA Y HORA DE EXPEDICION</label
                  >
                  <div class="row ml-1">
                    <input
                      type="date"
                      v-model="form.fecha_expedicion"
                      class="form-control form-control-sm col-md-6"
                      @input="validaFechas()"
                      :class="
                        $v.form.fecha_expedicion.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                    <input
                      type="text"
                      class="form-control form-control-sm col-md-5"
                      v-model="form.hora_expedicion"
                      @input="validaFechas()"
                      v-mask="{
                        mask: '99:99',
                        hourFormat: '24',
                      }"
                      :class="
                        $v.form.hora_expedicion.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                </div>
              </div>
              <div class="row" style="font-size: 12px">
                <!-- Producto Liquido -->
                <div class="form-group col-md-6">
                  <label for="producto_liquido">PRODUCTO LIQUIDO</label>
                  <v-select
                    v-model="producto_liquido"
                    class="form-control form-control-sm p-0"
                    placeholder="Nombre"
                    label="nombre"
                    :options="listasForms.productos_liquidos"
                    @input="selectProductosLiqui()"
                    :class="[
                      $v.form.producto_liquido_id.$invalid
                        ? 'is-invalid'
                        : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    disabled
                  ></v-select>
                </div>
                <!-- Remisión -->
                <div class="form-group col-md-6">
                  <label for="remision">N° REMISION</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    style="font-size: 13px"
                    v-model="form.remision"
                    placeholder="Orden de Cargue"
                    :class="
                      $v.form.remision.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="small-box bg-light p-2">
              <div class="row" style="font-size: 12px">
                <!-- Despachado -->
                <div class="col-md-8">
                  <label for="fecha_expedicion">DESPACHADO A:</label>
                  <v-select
                    v-model="cliente"
                    style="font-size: 13px"
                    class="form-control form-control-sm p-0"
                    :class="[
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                      $v.form.despachado_a.$invalid ? 'is-invalid' : 'is-valid',
                    ]"
                    placeholder="Clientes"
                    label="razon_social"
                    :options="listasForms.clientes"
                    @search="buscarClientes"
                    @input="selectCliente"
                  ></v-select>
                </div>
                <!-- Código -->
                <div class="form-group col-md-4">
                  <label for="codigo">CÓDIGO</label>
                  <input
                    type="text"
                    style="font-size: 13px"
                    v-model="form.codigo_destino"
                    placeholder="Codigo Destino"
                    class="form-control form-control-sm"
                    :class="
                      $v.form.codigo_destino.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                </div>
                <!-- Dirección -->
                <div class="form-group col-md-8">
                  <label for="direccion_destino">DIRECCIÓN:</label>
                  <input
                    type="text"
                    style="font-size: 13px"
                    v-model="form.direccion_destino"
                    placeholder="Dirección"
                    class="form-control form-control-sm"
                    :class="
                      $v.form.direccion_destino.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    disabled
                  />
                </div>
                <!-- CIUDAD DESTINO -->
                <div class="form-group col-md-4">
                  <label for="ciudad_destino">CIUDAD DESTINO</label>
                  <input
                    type="text"
                    style="font-size: 13px"
                    v-model="form.ciudad_destino"
                    placeholder="Ciudad Destino"
                    class="form-control form-control-sm"
                    :class="
                      $v.form.ciudad_destino.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                </div>
              </div>
            </div>
            <div class="small-box bg-light p-2">
              <div class="row" style="font-size: 12px">
                <!-- Cédula Conductor -->
                <div class="form-group col-md-4">
                  <label for="campo_productor">CÉDULA</label>
                  <input
                    type="number"
                    style="font-size: 13px"
                    v-model="form.documento_conductor"
                    placeholder="Cédula Conductor"
                    label="cedula"
                    class="form-control form-control-sm"
                    @change="buscarConductores()"
                    :class="
                      $v.form.documento_conductor.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    disabled
                  />
                </div>
                <!-- Nombre Conductor -->
                <div class="form-group col-md-8">
                  <label for="conductor">NOMBRE DEL CONDUCTOR</label>
                  <input
                    type="text"
                    v-model="form.nombre_conductor"
                    style="font-size: 13px"
                    placeholder="Nombre Conductor"
                    class="form-control form-control-sm"
                    :class="
                      $v.form.nombre_conductor.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    disabled
                  />
                </div>
                <!-- Transportadora -->
                <div class="form-group col-md-5">
                  <label for="empresa_transportadora"
                    >EMPRESA TRANSPORTADORA</label
                  >
                  <v-select
                    v-model="transportadora"
                    class="form-control form-control-sm p-0"
                    placeholder="Nombre"
                    label="razon_social"
                    :options="listasForms.transportadoras"
                    @input="selectTransportadora()"
                    :class="[
                      $v.form.transportadora_id.$invalid
                        ? 'is-invalid'
                        : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    disabled
                  ></v-select>
                </div>
                <!-- Vehículo -->
                <div class="form-group col-md-2">
                  <label for="cabezote">PLACA DEL VEHÍCULO</label>
                  <input
                    type="text"
                    style="font-size: 13px"
                    v-model="form.vehiculo"
                    placeholder="Placa Vehículo"
                    class="form-control form-control-sm"
                    @change="buscarVehiculos()"
                    :class="
                      $v.form.vehiculo.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    disabled
                  />
                </div>
                <!-- Remolque -->
                <div class="form-group col-md-2">
                  <label for="remolque">PLACA DEL REMOLQUE</label>
                  <input
                    type="text"
                    style="font-size: 13px"
                    v-model="form.remolque"
                    placeholder="Placa Remolque"
                    class="form-control form-control-sm"
                    @change="buscarRemolques()"
                    :class="
                      $v.form.remolque.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    disabled
                  />
                </div>
                <!-- Tipo Vehículo -->
                <div class="form-group col-md-3">
                  <label for="remolque">TIPO VEHÍCULO</label>
                  <v-select
                    v-model="tipo_vehiculo"
                    class="form-control form-control-sm p-0"
                    placeholder="Nombre"
                    label="nombre"
                    :options="listasForms.tipos_vehiculos"
                    @input="selectTiposVehiculos()"
                    :class="[
                      $v.form.tipo_vehiculo_id.$invalid
                        ? 'is-invalid'
                        : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                  ></v-select>
                </div>
              </div>
            </div>
            <div class="small-box bg-light p-2">
              <div class="row" style="font-size: 12px">
                <!-- Sitio Origen -->
                <div class="form-group col-md-6">
                  <label for="lugar_origen">LUGAR DE ORIGEN</label>
                  <v-select
                    v-model="sitio_origen"
                    class="form-control form-control-sm p-0"
                    placeholder="Nombre"
                    label="nombre"
                    :options="listasForms.sitios"
                    @input="selectSitioOrigen()"
                    :class="[
                      $v.form.sitio_origen_id.$invalid
                        ? 'is-invalid'
                        : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    disabled
                  ></v-select>
                </div>
                <!-- Fecha Cargue -->
                <div class="form-group col-md-3">
                  <label for="fecha_cargue">FECHA</label>
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    style="font-size: 13px"
                    v-model="form.fecha_cargue"
                    @input="validaFechas()"
                    :class="
                      $v.form.fecha_cargue.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  />
                </div>
                <!-- Hora Cargue -->
                <div class="form-group col-md-3">
                  <label for="hora_cargue">HORA DE SALIDA</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    style="font-size: 13px"
                    v-model="form.hora_cargue"
                    @input="validaFechas()"
                    v-mask="{
                      mask: '99:99',
                      hourFormat: '24',
                    }"
                    :class="
                      $v.form.hora_cargue.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  />
                </div>
                <!-- Sitio Destino -->
                <div class="form-group col-md-6">
                  <label for="lugar_origen">LUGAR DE DESTINO</label>
                  <v-select
                    v-model="sitio_destino"
                    class="form-control form-control-sm p-0"
                    placeholder="Nombre"
                    label="descripcion"
                    :options="listasForms.sitio_destino"
                    @input="selectLugarDestino"
                    @search="getLugarDestino"
                    :class="[
                      $v.form.sitio_destino.$invalid
                        ? 'is-invalid'
                        : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    disabled
                  ></v-select>
                  <div class="error" v-if="!form.despachado_a">
                    <span class="badge bg-danger">Diligencie Despachado A</span>
                  </div>
                </div>
                <!-- Horas Vigencia -->
                <div class="form-group col-md-3">
                  <label for="hora_vigencia">HORAS DE VIGENCIA</label>
                  <input
                    type="number"
                    style="font-size: 13px"
                    v-model="form.horas_vigencia"
                    placeholder="Horas Vigencia"
                    class="form-control form-control-sm"
                    :class="
                      $v.form.horas_vigencia.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                </div>
                <!-- Horas Adicional -->
                <div class="form-group col-md-3">
                  <label for="hora_adicional">HORA ADICIONAL</label>
                  <input
                    type="number"
                    style="font-size: 13px"
                    v-model="form.hora_adicional"
                    placeholder="Horas Adicional"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
            </div>
            <div class="small-box bg-light p-2">
              <div class="row" style="font-size: 12px">
                <!-- Sellos -->
                <div class="col-md-1 text-center">
                  <label for>TERCERO</label>
                  <input
                    v-model="terceroSellos"
                    type="checkbox"
                    value="1"
                    class="form-control form-control-sm"
                    @change="buscarSellos()"
                  />
                </div>
                <div class="form-group col-md-11">
                  <label for="sellos">SELLOS</label>
                  <v-select
                    :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                    label="numero"
                    v-model="form.sellos"
                    :options="listasForms.sellos"
                    @input="buscarSellos()"
                    placeholder="Sellos"
                    multiple
                  ></v-select>
                </div>
                <!-- Alerta Sellos -->
                <div
                  :class="[
                    form.sellos && form.sellos.length > 0
                      ? 'btn alert-default-success form-group col-md-12'
                      : 'btn alert-default-warning form-group col-md-12',
                  ]"
                >
                  <h5>
                    <i class="icon fas fexclamation-triangle"></i>
                    <b>Atención!</b>
                  </h5>

                  {{
                    form.sellos && form.sellos.length > 0
                      ? "Sellos Asignados" + " " + form.sellos.length
                      : "Por favor ingresar los sellos"
                  }}.
                </div>
                <!-- Gov -->
                <div class="form-group col-md-4">
                  <label for="gov">GOV</label>
                  <input
                    type="number"
                    style="font-size: 13px"
                    v-model="form.gov"
                    class="form-control form-control-sm"
                    step="any"
                    @blur="validarVolumenes"
                    :class="$v.form.gov.$invalid ? 'is-invalid' : 'is-valid'"
                  />
                </div>
                <!-- Gsv -->
                <div class="form-group col-md-4">
                  <label for="gsv">GSV</label>
                  <input
                    type="number"
                    style="font-size: 13px"
                    v-model="form.gsv"
                    class="form-control form-control-sm"
                    step="any"
                    @blur="validarVolumenes"
                    :class="$v.form.gsv.$invalid ? 'is-invalid' : 'is-valid'"
                  />
                  <div class="error">
                    <b>Máximos barriles: {{ max_barriles }}</b>
                  </div>
                </div>
                <!-- Nsv -->
                <div class="form-group col-md-4">
                  <label for="nsv">NSV</label>
                  <input
                    type="number"
                    style="font-size: 13px"
                    v-model="form.nsv"
                    class="form-control form-control-sm"
                    step="any"
                    @blur="validarVolumenes"
                    :class="$v.form.nsv.$invalid ? 'is-invalid' : 'is-valid'"
                  />
                </div>
                <!-- Observaciones -->
                <div class="form-group col-md-12">
                  <label for="observaciones">OBSERVACIONES</label>
                  <textarea
                    cols="30"
                    rows="4"
                    class="form-control form-control-sm"
                    v-model="form.observaciones"
                    :class="
                      $v.form.observaciones.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="small-box bg-light p-2">
              <div class="row justify-content-center" style="font-size: 12px">
                <!-- Analisis Laboratorio -->
                <div class="form-group col-md-3">
                  <label for="temp">ANÁLISIS DE LAB (TEMP°)</label>
                  <input
                    type="number"
                    style="font-size: 13px"
                    v-model="form.analisis_laboratorio"
                    min="50"
                    max="250"
                    @blur="validarTemp"
                    class="form-control form-control-sm"
                    :class="
                      $v.form.analisis_laboratorio.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                </div>
                <!-- Factor Temperatura -->
                <div class="form-group col-md-3">
                  <label for="factor">FACTOR TEMPERATURA</label>
                  <input
                    type="number"
                    style="font-size: 13px"
                    v-model="form.factor_temperatura"
                    class="form-control form-control-sm"
                    :class="
                      $v.form.factor_temperatura.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                </div>
                <!-- Api -->
                <div class="form-group col-md-2">
                  <label>A.P.I</label>
                  <input
                    type="number"
                    style="font-size: 13px"
                    v-model="form.api"
                    @blur="validarApi"
                    class="form-control form-control-sm"
                    :class="$v.form.api.$invalid ? 'is-invalid' : 'is-valid'"
                  />
                </div>
                <!-- Bsw -->
                <div class="form-group col-md-2">
                  <label for="bsw">B.S.W</label>
                  <input
                    type="number"
                    style="font-size: 13px"
                    v-model="form.bsw"
                    @blur="validarBsw"
                    class="form-control form-control-sm"
                    :class="$v.form.bsw.$invalid ? 'is-invalid' : 'is-valid'"
                  />
                </div>
                <!-- Azufre -->
                <div class="form-group col-md-2">
                  <label for="azufre">S%</label>
                  <input
                    type="number"
                    style="font-size: 13px"
                    v-model="form.azufre"
                    @blur="validarAzufre"
                    class="form-control form-control-sm"
                    :class="$v.form.azufre.$invalid ? 'is-invalid' : 'is-valid'"
                  />
                </div>
              </div>
            </div>
            <div class="small-box bg-light p-2">
              <div class="row" style="font-size: 12px">
                <!-- Novedades -->
                <div class="col-md-12">
                  <label for="novedades">NOVEDADES - PROCESO STAND BY</label>
                  <v-select
                    v-model="novedades"
                    label="descripcion"
                    :options="listasForms.novedades"
                    placeholder="Novedades"
                    multiple
                    :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                    @input="selectNovedades()"
                  ></v-select>
                  <div class="error" v-if="novedades.length == 0">
                    <span class="badge bg-danger"
                      >Seleccione las novedades</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer alert-default-success">
            <div class="row">
              <div class="col-md-2">
                <button class="btn bg-gray-dark col-md-11" @click="back()">
                  <i class="fas fa-reply"></i>
                  <br />Volver
                </button>
              </div>
              <div class="col-md-2">
                <button
                  class="btn bg-navy col-md-11"
                  v-show="
                    !$v.form.$invalid &&
                      digito_verificacion != null &&
                      digito_verificacion != ''
                  "
                  @click="saveDetGuia()"
                >
                  <i class="fas fa-paper-plane"></i>
                  <br />Guardar
                </button>
              </div>
              <div class="col-md-2">
                <a
                  id="botonImp"
                  type="button"
                  class="btn bg-cyan col-md-11"
                  @click="print()"
                  v-show="accion == 'Show'"
                >
                  <i class="fas fa-print"></i>
                  <br />Imprimir Guía
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {
  required,
  helpers,
  numeric,
  minValue,
  maxValue,
} from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";
import moment from "moment";
import $ from "jquery";

const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);
export default {
  name: "DetGuiasVentasTercerosForm",
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      form: {
        guia_id: null,
        numero_guia: null,
        tipo_producto_id: null,
        ciudad_origen_id: null,
        fecha_expedicion: null,
        hora_expedicion: null,
        producto_liquido_id: null,
        remision: null,
        despachado_a: null,
        guia: null,
        codigo_destino: null,
        direccion_destino: null,
        ciudad_destino: null,
        documento_conductor: null,
        nombre_conductor: null,
        transportadora_id: null,
        transportadora: null,
        nombre_transportadora: null,
        vehiculo: null,
        remolque: null,
        tipo_vehiculo_id: null,
        sitio_origen_id: null,
        sitio_origen: null,
        fecha_cargue: null,
        hora_cargue: null,
        sitio_destino: null,
        horas_vigencia: null,
        hora_adicional: null,
        gov: null,
        gsv: null,
        nsv: null,
        digito_verificacion: null,
        analisis_laboratorio: null,
        factor_temperatura: null,
        observaciones: null,
        api: null,
        bsw: null,
        azufre: null,
        tipo_operacion: null,
        sellos: [],
        acto: null,
      },
      digito_verificacion: null,
      ciudad_origen: {},
      tipo_producto: {},
      ciudad_destino: {},
      transportadora: {},
      sitio_origen: {},
      tipo_vehiculo: {},
      cliente: {},
      sitio_destino: {},
      novedades: {},
      producto_liquido: {},
      sitio_type: this.$route.params.sitio_type,
      sitio: {},
      guia: {},
      terceroSellos: 0,
      tercero: 0,
      max_barriles: 0,
      id: null,
      accion: "",
      metodo: "",
      estadoGuia: null,
      listasForms: {
        tipo_productos: [],
        tipos_vehiculos: [],
        transportadoras: [],
        ventanas_descargue: [],
        guias: [],
        novedades: [],
        sitio_destino: [],
        clientes: [],
        productos_liquidos: [],
      },
    };
  },
  validations() {
    let guia = {};

    if (this.accion == "Cambiar") {
      guia = {
        required,
      };
    } else {
      guia = {
        required: false,
      };
    }

    return {
      guia: guia,
      digito_verificacion: {
        required,
        numeric,
        minValue: minValue(0),
        maxValue: maxValue(9),
      },
      form: {
        ciudad_destino: {
          required,
        },
        numero_guia: {
          required,
        },
        tipo_producto_id: {
          required,
        },
        ciudad_origen_id: {
          required,
        },
        fecha_expedicion: {
          required,
        },
        hora_expedicion: {
          required,
          timeFormat,
        },
        producto_liquido_id: {
          required,
        },
        remision: {
          required,
        },
        despachado_a: {
          required,
        },
        codigo_destino: {
          required,
        },
        direccion_destino: {
          required,
        },
        documento_conductor: {
          required,
        },
        nombre_conductor: {
          required,
        },
        transportadora_id: {
          required,
        },
        vehiculo: {
          required,
        },
        remolque: {
          required,
        },
        tipo_vehiculo_id: {
          required,
        },
        sitio_origen_id: {
          required,
        },
        fecha_cargue: {
          required,
        },
        hora_cargue: {
          required,
          timeFormat,
        },
        sitio_destino: {
          required,
        },
        horas_vigencia: {
          required,
        },
        gov: {
          required,
        },
        gsv: {
          required,
        },
        nsv: {
          required,
        },
        analisis_laboratorio: {
          required,
        },
        factor_temperatura: {
          required,
        },
        observaciones: {
          required,
        },
        api: {
          required,
        },
        bsw: {
          required,
        },
        azufre: {
          required,
        },
      },
    };
  },

  methods: {
    async init() {
      this.cargando = true;
      this.accion = this.$route.params.accion;
      if (this.form.sitio_type == "App\\Sitio") {
        this.form.sitio_type = 1;
      } else if (this.form.sitio_type == "App\\PuntoGestion") {
        this.form.sitio_type = 2;
      }

      // this.sitio_type = this.$route.params.sitio_type;
      this.sitio_id = this.$route.params.sitio_id;
      this.tipo_control = this.$route.params.tipo_control;
      this.accion = this.$route.params.accion;
      if (this.accion == "Diligenciar") {
        this.metodo = "POST";
        let dataParams = this.$route.params;
        let viaje_tercero = dataParams.data_edit;
        this.max_barriles = viaje_tercero.volumen_calculado;
        this.form = viaje_tercero;
        this.form.postulacion_id = dataParams.postulacion_id;
        this.form.remision;
        this.form.acto = 1;
        this.form.sellos = [];
        this.form.sello_id = null;
        this.form.api = null;
        this.form.novedades = [];
        this.form.nombre_conductor = viaje_tercero.conductor;
        this.form.fecha_cargue = moment().format("YYYY-MM-DD");
        this.form.hora_cargue = moment().format("HH:mm:ss");

        if (viaje_tercero.tipo_vehiculo_id != null) {
          this.tipo_vehiculo = {
            id: viaje_tercero.tipo_vehiculo.id,
            nombre: viaje_tercero.tipo_vehiculo.nombre,
          };
        }
        this.sitio_origen = {
          id: viaje_tercero.sitio_origen.id,
          nombre: viaje_tercero.sitio_origen.nombre,
        };
        this.ciudad_origen = {
          id: viaje_tercero.ciudad_origen.id,
          nombre: viaje_tercero.ciudad_origen.nombre,
        };
        this.transportadora = {
          id: viaje_tercero.transportadora.id,
          razon_social: viaje_tercero.transportadora.razon_social,
        };
        this.cliente = {
          id: viaje_tercero.cliente.id,
          razon_social: viaje_tercero.cliente.razon_social,
        };
        this.producto_liquido = {
          id: viaje_tercero.producto_liquido.id,
          nombre: viaje_tercero.producto_liquido.nombre,
        };
        this.alertInicio();
        await this.buscarGuiaInput();
        await this.buscarSellos();
        await this.getLugarDestino();
        await this.llenarSitioDestino(viaje_tercero);
        this.cargando = false;
      } else if (this.accion == "Show") {
        this.metodo = "POST";
        this.form.acto = 2;
        this.cargando = false;
      } else if (this.accion == "Cambiar") {
        this.metodo = "POST";
        let dataParams = this.$route.params;
        let viaje_tercero = dataParams.data_edit;
        this.max_barriles = viaje_tercero.volumen_calculado;
        this.form = viaje_tercero;
        this.form.postulacion_id = dataParams.postulacion_id;
        this.form.remision;
        this.form.acto = 3;
        this.form.sello_id = null;
        this.form.nombre_conductor = viaje_tercero.conductor;
        let fechaExpe = viaje_tercero.fecha_expedicion.slice(0, 10);
        let horaExpe = viaje_tercero.fecha_expedicion.slice(11, 19);
        this.form.fecha_expedicion = fechaExpe;
        this.form.hora_expedicion = horaExpe;
        this.form.guia = viaje_tercero.guia;
        if (viaje_tercero.tipo_vehiculo_id != null) {
          this.tipo_vehiculo = {
            id: viaje_tercero.tipo_vehiculo.id,
            nombre: viaje_tercero.tipo_vehiculo.nombre,
          };
        }
        this.sitio_origen = {
          id: viaje_tercero.sitio_origen.id,
          nombre: viaje_tercero.sitio_origen.nombre,
        };
        this.ciudad_origen = {
          id: viaje_tercero.ciudad_origen.id,
          nombre: viaje_tercero.ciudad_origen.nombre,
        };
        this.transportadora = {
          id: viaje_tercero.transportadora.id,
          razon_social: viaje_tercero.transportadora.razon_social,
        };
        this.cliente = {
          id: viaje_tercero.cliente.id,
          razon_social: viaje_tercero.cliente.razon_social,
        };
        this.producto_liquido = {
          id: viaje_tercero.tipo_producto.id,
          nombre: viaje_tercero.tipo_producto.nombre,
        };
        this.tipo_producto = {
          id: viaje_tercero.tipo_producto.id,
          nombre: viaje_tercero.tipo_producto.nombre,
        };
        this.novedades = viaje_tercero.novedades;
        this.alertInicio();
        await this.buscarGuiaInput();
        await this.buscarSellos();
        await this.getLugarDestino();
        await this.llenarSitioDestino(viaje_tercero);
        this.cargando = false;
      } else if (this.$route.params.accion == "Actualizar") {
        this.metodo = "POST";
        let dataParams = this.$route.params;
        let viaje_tercero = dataParams.data_edit;
        this.max_barriles = viaje_tercero.volumen_calculado;
        this.form = viaje_tercero;
        this.form.postulacion_id = dataParams.postulacion_id;
        this.form.remision;
        this.form.acto = 4;
        this.form.sello_id = null;
        this.form.nombre_conductor = viaje_tercero.conductor;
        let fechaExpe = viaje_tercero.fecha_expedicion.slice(0, 10);
        let horaExpe = viaje_tercero.fecha_expedicion.slice(11, 19);
        this.form.fecha_expedicion = fechaExpe;
        this.form.hora_expedicion = horaExpe;
        this.guiaDv(viaje_tercero);
        await this.getLugarDestino();
        await this.llenarSitioDestino(viaje_tercero);

        if (viaje_tercero.tipo_vehiculo_id != null) {
          this.tipo_vehiculo = {
            id: viaje_tercero.tipo_vehiculo.id,
            nombre: viaje_tercero.tipo_vehiculo.nombre,
          };
        }
        this.sitio_origen = {
          id: viaje_tercero.sitio_origen.id,
          nombre: viaje_tercero.sitio_origen.nombre,
        };
        this.ciudad_origen = {
          id: viaje_tercero.ciudad_origen.id,
          nombre: viaje_tercero.ciudad_origen.nombre,
        };
        this.transportadora = {
          id: viaje_tercero.transportadora.id,
          razon_social: viaje_tercero.transportadora.razon_social,
        };
        this.cliente = {
          id: viaje_tercero.cliente.id,
          razon_social: viaje_tercero.cliente.razon_social,
        };
        this.producto_liquido = {
          id: viaje_tercero.tipo_producto.id,
          nombre: viaje_tercero.tipo_producto.nombre,
        };

        this.tipo_producto = {
          id: viaje_tercero.tipo_producto.id,
          nombre: viaje_tercero.tipo_producto.nombre,
        };

        this.novedades = viaje_tercero.novedades;
        this.alertInicio();
        await this.buscarSellos();
        await this.getNovedades();
        this.cargando = false;
      } else {
        this.cargando = false;
        this.id = this.$route.params.id;
      }
    },

    guiaDv(viaje) {
      let numero = viaje.numero_guia.slice(0, -1);
      let numeroComoString = viaje.numero_guia.toString();
      let ultimoDigito = numeroComoString.slice(-1);
      this.form.numero_guia = numero;
      this.form.digito_verificacion = ultimoDigito;
      this.digito_verificacion = ultimoDigito;
    },

    async llenarSitioDestino(viaje) {
      let resultadoFiltro = this.listasForms.sitio_destino.filter((item) => {
        return item.descripcion.trim().includes(viaje.sitio_destino.trim());
      });

      if (resultadoFiltro.length > 0) {
        this.sitio_destino = resultadoFiltro[0];
      }
    },

    async getTiposProducto() {
      await axios
        .get("/api/admin/productos/lista", {
          params: {
            linea_negocio_id: 2,
          },
        })
        .then((response) => {
          this.listasForms.tipo_productos = response.data;
        });
    },

    async getCiudad() {
      await axios.get("/api/admin/ciudades/lista").then((response) => {
        this.listasForms.ciudades = response.data;
      });
    },

    async getTiposVehiculos() {
      let me = this;
      var url = "api/admin/tiposVehiculos/lista";
      await axios
        .get(url, {
          params: {
            linea_negocio_id: 2,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.tipoVehiculo = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async buscarTransportadora() {
      let me = this;
      var url = "api/admin/empresas/lista";
      await axios
        .get(url, {
          params: {
            linea_negocio_id: 2,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.transportadoras = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async buscarConductores() {
      this.cargando = true;
      let dato = this.form.documento_conductor;
      let res = await this.validarStatus(dato, "C");
      /* Existe Conductor */
      if (res && res.length != 0) {
        /* Documentación */
        if (res.validacion.error) {
          await this.msgDocumentacion(res);
          this.cargando = false;
          if (res.validacion.error.documentos !== undefined) {
            if (
              res.validacion.error.documentos.obligatorios !== undefined ||
              res.validacion.error.documentos.vencidos !== undefined ||
              res.validacion.error.documentos.no_verificados !== undefined ||
              res.validacion.error.inactivo == false
            ) {
              this.form.documento_conductor = null;
              this.form.nombre_conductor = null;
              return false;
            }
          }
          if (res.validacion.error.inactivo == false) {
            this.form.documento_conductor = null;
            this.form.nombre_conductor = null;
            return false;
          }
        }
        /* Falta Campo Formulario Conductor */
        if (res["info"] == false) {
          await this.$swal({
            icon: "error",
            title: `Hacen falta campos por diligenciar en el formulario de creacion de Conductores`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.form.documento_conductor = null;
          this.form.nombre_conductor = null;
          return false;
        }
        /* Linea Negocio Conductor */
        if (!res["has_lineas"]) {
          await this.$swal({
            icon: "error",
            title: `El Conductor no se encuentra en la linea de negocio Hidrocarburos`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.form.documento_conductor = null;
          this.form.nombre_conductor = null;
          return false;
        }
        /* Viaje Activo */
        /* if (res.viaje.length > 0) {
         this.form.documento_conductor = null;
          this.form.nombre_conductor = null;
          await this.$swal({
            icon: "error",
            title: `El conductor se encuentra activo dentro de un viaje`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        } */
        /* Postulación Activa */
        /* if (res.postulacion.length > 0) {
          this.form.documento_conductor = null;
          this.form.nombre_conductor = null;
          await this.$swal({
            icon: "error",
            title: `El conductor cuenta con una postulacion activa`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        } */
        /* Postulación Adicional Activa */
        /* if (res.postulacion_adicionales.length > 0) {
           this.form.documento_conductor = null;
          this.form.nombre_conductor = null;
          await this.$swal({
            icon: "error",
            title: `El conductor ya está en un proceso de postulacion de adicionales, espere a que este finalice`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 6000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        } */
        await this.$swal({
          icon: "success",
          title: "El conductor es apto..",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        await this.selectConductor(res[0]);
      } else {
        this.form.conductor_id = null;
        await this.$swal({
          icon: "warning",
          title: "El conductor digitado NO se encuentra registrado.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
      this.cargando = false;
    },

    async buscarVehiculos() {
      this.cargando = true;
      let dato = this.form.vehiculo;
      let res = await this.validarStatus(dato, "V"); //SVC962, THX689
      /* Existe Vehículo */
      if (res && res.length != 0) {
        /* Dcoumentación */
        if (res.validacion.error) {
          this.cargando = false;
          await this.msgDocumentacion(res);
          if (res.validacion.error.documentos != undefined) {
            if (
              res.validacion.error.documentos.obligatorios !== undefined ||
              res.validacion.error.documentos.vencidos !== undefined ||
              res.validacion.error.documentos.no_verificados !== undefined
            ) {
              this.form.vehiculo = null;
              return false;
            }
          }
          if (res.validacion.error.inactivo == false) {
            this.form.vehiculo = null;
            return false;
          }
        }

        /* Viajes Activos */
        /* if (res.viaje.length > 0) {
          this.form.vehiculo = null;
          await this.$swal({
            icon: "error",
            title: `El vehículo se encuentra activo dentro de un viaje`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        } */

        //Validar si ya se encuentra con una postulacion
        /* if (res.postulacion.length > 0) {
          this.form.vehiculo = null;
          await this.$swal({
            icon: "error",
            title: `El vehículo cuenta con una postulación activa`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        } */

        //Validar si ya se encuentra con una postulacion adicionales
        /* if (res.postulacion_adicionales.length > 0) {
          this.form.vehiculo = null;
          await this.$swal({
            icon: "error",
            title: `El vehículo ya está en un proceso de postulación de adicionales, espere a que este finalice`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 6000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        } */

        //Validar si el vehículo tiene peso asignado.
        if (res[0].peso_vacio == null || res[0].peso_vacio == 0) {
          this.fomr.vehiculo = null;
          await this.$swal({
            icon: "error",
            title: `El vehículo no tiene peso asignado.`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        }

        /* Campos Vacios */
        if (res["info"] == false) {
          await this.$swal({
            icon: "error",
            title: `Hacen falta campos por diligenciar en el formulario de creacion de Vehículos`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.form.vehiculo = null;
          this.cargando = false;
          return false;
        }

        /* Línea Negocio */
        if (!res["has_lineas"]) {
          await this.$swal({
            icon: "error",
            title: `El vehículo no se encuentra en la linea de negocio Hidrocarburos`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.form.vehiculo = null;
          this.cargando = false;
          return false;
        }

        await this.$swal({
          icon: "success",
          title: "El vehículo es apto..",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        await this.selectVehiculo(res[0]);
      } else {
        this.form.vehiculo_id = null;
        this.$swal({
          icon: "warning",
          title: "El vehículo digitado NO se encuentra registrado.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
        this.cargando = false;
        return false;
      }
      this.cargando = false;
    },

    async buscarRemolques() {
      this.cargando = true;
      let dato = this.form.remolque;
      let res = await this.validarStatus(dato, "R");
      /* Existe Remolque */
      if (res && res.length != 0) {
        /* Documentación */
        if (res.validacion.error) {
          await this.msgDocumentacion(res);
          this.cargando = false;
          if (res.validacion.error.documentos !== undefined) {
            if (
              res.validacion.error.documentos.obligatorios !== undefined ||
              res.validacion.error.documentos.vencidos !== undefined ||
              res.validacion.error.documentos.no_verificados !== undefined ||
              res.validacion.error.inactivo == false
            ) {
              this.form.remolque = null;
              this.cargando = false;
              return false;
            }
          }

          if (res.validacion.error.inactivo == false) {
            this.remolque.placa = null;
            this.formEnvio.remolque_id = null;
            this.formEnvio.remolque = {};
            this.cargando = false;
            return false;
          }
        }

        /* Campos Faltantes */
        if (res["info"] == false) {
          await this.$swal({
            icon: "error",
            title: `Hacen falta campos por diligenciar en el formulario de creacion de Relmoques`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.form.remolque = null;
          this.cargando = false;
          return false;
        }

        /* Línea Negocio */
        if (!res["has_lineas"]) {
          await this.$swal({
            icon: "error",
            title: `El Remolque no se encuentra en la linea de negocio Hidrocarburos`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.form.remolque = null;
          this.cargando = false;
          return false;
        }

        /* Viaje Activo */
        /* if (res.viaje.length > 0) {
          this.form.remolque = null;
          await this.$swal({
            icon: "error",
            title: `El remolque se encuentra activo dentro de un viaje`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        } */

        /* Postulación Activa */
        /* if (res.postulacion.length > 0) {
          this.form.remolque = null;
          await this.$swal({
            icon: "error",
            title: `El remolque cuenta con una postulacion activa`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        } */

        /* Validar si el rermolque esta en una postulacion de adicionales. */
        /* if (res.postulacion_adicionales.length > 0) {
          this.form.remolque = null;
          await this.$swal({
            icon: "error",
            title: `El remolque ya está en un proceso de postulacion de adicionales, espere a que este finalice`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 6000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        } */

        //Validar si el rermolque tiene peso asignado.
        /*  if (res[0].peso_vacio == null || res[0].peso_vacio == 0) {
          this.form.remolque = null;
          await this.$swal({
            icon: "error",
            title: `El remolque no tiene peso asignado.`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.cargando = false;
          return false;
        } */

        await this.$swal({
          icon: "success",
          title: "El remolque es apto..",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
        await this.selectRemolque(res[0]);
      } else {
        this.form.remolque_id = null;
        this.$swal({
          icon: "warning",
          title: "El remolque digitado NO se encuentra registrado.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }

      this.cargando = false;
    },

    async getTipoVh() {
      await axios
        .get("api/admin/tiposVehiculos/lista", {
          params: {
            linea_negocio_id: 2,
          },
        })
        .then((response) => {
          this.listasForms.tipos_vehiculos = response.data;
        });
    },

    async getNovedades() {
      await axios.get("/api/lista/52").then((response) => {
        this.listasForms.novedades = response.data;
      });
    },

    async getLugarDestino() {
      await axios
        .get("/api/lista/196", {
          params: {
            cod_alternos: [
              this.form.cliente_id == 20 ? 22 : this.form.cliente_id,
            ],
          },
        })
        .then((response) => {
          this.listasForms.sitio_destino = response.data;
          let respuesta = response.data;
          let lista = [];
          for (let i = 0; i < respuesta.length; i++) {
            let nombre = respuesta[i].descripcion.split(",");
            lista.push({
              id: respuesta[i].id,
              cod_alterno: respuesta[i].cod_alterno,
              descripcion: nombre[0],
              direccion: nombre[1],
            });
          }
          this.listasForms.sitio_destino = lista;
        });
    },

    async getSitio() {
      let me = this;
      this.listasForms.sitios = [];
      var url = "api/admin/sitios/lista";
      await axios
        .get(url)
        .then(function(response) {
          me.listasForms.sitios = response.data;
        })
        .catch(function(e) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getVentanaEnturnamiento() {
      axios
        .get("api/hidrocarburos/ventanasEnturnamiento/lista", {
          params: {
            tipo_ventana: 2,
            sitio: this.form.sitio_destino_id,
          },
        })
        .then((response) => {
          this.listasForms.ventanas_descargue = response.data;
        });
    },

    buscarGuiaInput() {
      let me = this;
      me.cargando = true;
      let url = "api/hidrocarburos/guias/lista";
      let tipo_empresa = 1;
      let anulacion = 0;
      if (me.form.acto == 3) {
        anulacion = 1;
      }
      if (me.tercero == true) {
        tipo_empresa = 2;
      }
      axios
        .get(url, {
          params: {
            sitio_id: me.form.sitio_origen_id,
            tipo_empresa,
            tipo_producto_id: me.form.tipo_producto_id,
            anulacion: anulacion,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.form.numero_guia = null;

          if (anulacion) {
            me.listasForms.guias = respuesta;            
          } else {            
            if (respuesta.numero == undefined) {
              me.$swal({
                text: "No se encontro ninguna guia en el stock",
                icon: "error",
                confirmButtonText: "Aceptar!",
              });
            } else {
              if (respuesta.digito_verificacion != null) {
                me.form.numero_guia = respuesta.numero;
                me.form.digito_verificacion = respuesta.digito_verificacion;
                me.digito_verificacion = respuesta.digito_verificacion;
              } else {
                me.form.numero_guia = respuesta.numero;
                //me.formEnvio.numeroGuia = respuesta.numero;
              }
              me.form.guia_id = respuesta.id;
              me.estadoGuia = respuesta.estado;
            }
          }
          me.cargando = false;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getProductoLiquido() {
      await axios.get("api/hidrocarburos/Productos/lista").then((response) => {
        this.listasForms.productos_liquidos = response.data;
      });
    },

    async buscarSellos() {
      let me = this;
      me.cargando = true;
      let url = "api/hidrocarburos/sellos/lista";
      let tipo_empresa = 1;

      if (me.terceroSellos == true) {
        tipo_empresa = 2;
      }
      await axios
        .get(url, {
          params: {
            tipo_empresa,
            sitio_id: me.form.sitio_origen_id,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.sellos = respuesta;
          me.form.sello_id = me.listasForms.sellos.id;
          me.cargando = false;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarClientes(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/com/clientes/lista?razon_social=" + search;
        axios
          .get(url, {
            params: { linea_negocio_id: 11 },
          })
          .then(function(response) {
            me.listasForms.clientes = response.data;
            loading(false);
          })
          .catch(function(error) {
            loading(false);
            me.$swal({
              icon: "error",
              title: "Ocurrió un error - " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    /* Seleccionar listas */
    selectTipoProducto() {
      this.form.tipo_producto_id = null;
      if (this.tipo_producto) {
        this.form.tipo_producto_id = this.tipo_producto.id;
      }
    },

    selectCliente() {
      this.form.cliente_id = null;
      this.form.despachado_a = null;
      //this.form.sitio_destino = null;
      //this.form.direccion_destino = null;
      //this.sitio_destino = [];
      if (this.cliente) {
        this.form.cliente_id = this.cliente.id;
        this.form.despachado_a = this.cliente.razon_social;
        this.getLugarDestino();
      }
    },

    selectempresa() {
      this.form.transportadora_id = null;
      if (this.transportadora) {
        this.form.transportadora_id = this.transportadora.id;
      }
    },

    selectCiudadOrigen() {
      this.form.ciudad_origen_id = null;
      if (this.ciudad_origen) {
        this.form.ciudad_origen_id = this.ciudad_origen.id;
      }
    },

    selectConductor(conductor) {
      if (this.form.documento_conductor) {
        this.form.conductor_id = conductor.id;
        this.form.documento_conductor = conductor.numero_documento;
        this.form.nombre_conductor =
          conductor.nombres + " " + conductor.apellidos;
      }
    },

    selectVehiculo(vehiculo) {
      if (this.form.vehiculo) {
        this.form.vehiculo_id = vehiculo.id;
        this.form.vehiculo = vehiculo.placa;
      }
    },

    selectTiposVehiculos() {
      this.form.tipo_vehiculo_id = null;
      if (this.tipo_vehiculo) {
        this.form.tipo_vehiculo_id = this.tipo_vehiculo.id;
      }
    },

    selectTransportadora() {
      this.form.transportadora_id = null;
      if (this.transportadora) {
        this.form.transportadora_id = this.transportadora.id;
      }
    },

    selectRemolque(remolque) {
      if (this.form.remolque) {
        this.form.remolque_id = remolque.id;
        this.form.remolque = remolque.placa;
      }
    },

    selectSitioOrigen() {
      this.form.sitio_origen_id = null;
      if (this.sitio_origen) {
        this.form.sitio_origen_id = this.sitio_origen.id;
        this.form.sitio_origen = this.sitio_origen.nombre;
      }
    },

    selectLugarDestino() {
      this.form.sitio_destino = null;
      this.form.direccion_destino = null;
      if (this.sitio_destino) {
        this.form.sitio_destino = this.sitio_destino.descripcion;
        this.form.direccion_destino = this.sitio_destino.direccion;
      }
    },

    selectVentanaDescargue() {
      this.form.ventana_enturne_descargue_id = null;
      if (this.ventana_descargue) {
        this.form.ventana_enturne_descargue_id = this.ventana_descargue.id;
      }
    },

    selectNovedades() {
      this.form.novedades = [];
      if (this.novedades) {
        this.form.novedades = this.novedades.map((e) => e.numeracion);
      }
    },

    selectProductosLiqui() {
      this.form.producto_liquido_id = [];
      if (this.producto_liquido) {
        this.form.producto_liquido_id = this.producto_liquido.id;
      }
    },

    selectGuia() {
      if (this.guia) {
        this.form.guia_id = this.guia.id;
        this.form.numero_guia = this.guia.numero;
        this.form.digito_verificacion = this.guia.digito_verificacion
          ? this.guia.digito_verificacion
          : null;
        this.digito_verificacion = this.guia.digito_verificacion;
        this.estadoGuia = this.guia.estado;
      } else {
        this.form.guia_id = null;
        this.form.numero_guia = null;
        this.form.digito_verificacion = null;
        this.digito_verificacion = null;
      }
    },

    selectDv() {
      this.form.digito_verificacion = null;
      if (this.digito_verificacion) {
        this.form.digito_verificacion = this.digito_verificacion;
      }
    },

    async validarStatus(dato, tipo) {
      let me = this;
      let url = "";
      let entidad = null;
      if (tipo == "C") {
        entidad = "Conductor";
        url = "api/hidrocarburos/postulaciones/listaConductores";
      } else if (tipo == "R") {
        entidad = "Remolque";
        url = "api/hidrocarburos/postulaciones/listaRemolques";
      } else if (tipo == "V") {
        entidad = "Vehiculo";
        url = "api/hidrocarburos/postulaciones/listaVehiculos";
      }

      let params = {
        entidad,
        dato,
      };

      var res = null;
      await axios
        .get(url, {
          params,
        })
        .then((response) => {
          res = response.data;
        })
        .catch(function(e) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error - " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
      return res;
    },

    async msgDocumentacion(res) {
      if (res.validacion.error) {
        let erroHtml = "<div>";
        if (res.validacion.error.documentos !== undefined) {
          if (res.validacion.error.documentos.obligatorios) {
            erroHtml += "<b>Pendiente:</b><br>";
            erroHtml += res.validacion.error.documentos.obligatorios.join(
              "<br>"
            );
          }
          if (res.validacion.error.documentos.vencidos) {
            erroHtml += "<br><b>Vencidos:</b><br>";
            for (
              let i = 0;
              i < res.validacion.error.documentos.vencidos.length;
              i++
            ) {
              erroHtml +=
                res.validacion.error.documentos.vencidos[i].documento +
                " - " +
                res.validacion.error.documentos.vencidos[i].fecha_vencimiento +
                "<br>";
            }
          }
          if (res.validacion.error.documentos.no_verificados) {
            erroHtml += "<br><b>No Verificados:</b><br>";
            erroHtml += res.validacion.error.documentos.no_verificados.join(
              "<br>"
            );
          }
          erroHtml += "</div>";
          await this.$swal({
            html: erroHtml,
            showCancelButton: false,
            focusConfirm: true,
            icon: "error",
            title: "Aviso en documentacion",
            showConfirmButton: false,
            showCloseButton: true,
            footer:
              "<small style='text-align:center'><b>Desde el 1 de abril se aplicaran las restricciones</b>, por favor tenga al dia la documentacion de sus vehiculos, para mas informacion comuniquese con la mesa de ayuda al siguiente numero <b>3176431376 Opción 3</b></small>",
          });
        } else {
          this.$swal({
            icon: "error",
            title: res.validacion.error.msg,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        }
      }
    },

    /* Validaciones */
    validaFechas() {
      if (
        this.form.fecha_expedicion &&
        this.form.hora_expedicion &&
        this.form.fecha_cargue &&
        this.form.hora_cargue
      ) {
        var fecha_menor = new Date(
          this.form.fecha_expedicion + " " + this.form.hora_expedicion
        );

        var fecha_mayor = new Date(
          this.form.fecha_cargue + " " + this.form.hora_cargue
        );

        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.$swal({
            icon: "error",
            title: `La fecha y hora de expedición no puede ser mayor a la fecha y hora de salida...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.form.fecha_expedicion = null;
          this.form.hora_expedicion = null;
        }
      }

      //Validacion fechas descargue
      if (
        this.form.fecha_descargue &&
        this.form.hora_descargue &&
        this.form.fecha_enturne_descargue &&
        this.form.hora_enturne_descargue
      ) {
        var fecha_enturne = new Date(
          this.form.fecha_enturne_descargue +
            " " +
            this.form.hora_enturne_descargue
        );

        var fecha_descargue = new Date(
          this.form.fecha_descargue + " " + this.form.hora_descargue
        );

        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_enturne > fecha_descargue) {
          this.$swal({
            icon: "error",
            title: `La fecha y hora de enturne descargue no puede ser mayor a la fecha y hora de descargue...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.form.fecha_expedicion = null;
          this.form.hora_expedicion = null;
        }
      }
    },

    async saveDetGuia() {
      var confirm = true;
      if (this.form.sellos.length == 0) {
        this.cargando = false;
        await this.$swal({
          icon: "warning",
          title: "Advertencia!!",
          text: "No se han asignado los sellos",
          timer: 3000,
          backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
          timerProgressBar: true,
          confirmButtonText: "Aceptar",
        });
        return false;
      }
      if (this.novedades.length == 0) {
        this.cargando = false;
        await this.$swal({
          icon: "warning",
          title: "Advertencia!!",
          text: "No se han asignado las novedades",
          timer: 3000,
          backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
          timerProgressBar: true,
          confirmButtonText: "Aceptar",
        });
        return false;
      }
      if (this.form.acto == 3) {
        confirm = false;
        await this.$swal({
          title: "Esta seguro de anular y cambiar la guía?",
          text: "Este cambio no se puede revertir",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Anular y cambiar!",
        }).then((result) => {
          if (result.value) {
            confirm = true;
          }
        });
      }
      if (confirm) {
        if (!this.form.hora_adicional && this.form.acto != 4) {
          this.$swal({
            title: "Hora Adicional",
            text: "Esta seguro de que no desea asignar horas adicionales?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si, Aceptar!",
          }).then(async (result) => {
            if (result.value) {
              await this.save();
            }
          });
        } else {
          await this.save();
        }
      }
    },

    async save() {
      this.cargando = true;
      if (this.form.acto !== 4) {
        this.estadoGuia = 6;
      }
      await axios({
        method: this.metodo,
        url: "/api/hidrocarburos/detalleGuiasVentasTerceros",
        data: this.form,
      })
        .then((response) => {
          this.cargando = false;
          if (this.form.acto !== 4) {
            this.$route.params.accion = "Show";
            this.$route.params.data_edit = response.data;
            this.init();
            //this.formEnvio.id = response.data.id;
          }
          this.$swal({
            icon: "success",
            title: "La guía se guardó exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrio un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    alertInicio() {
      this.$swal({
        text:
          "Por favor verifique el nombre del conductor y las placas del cabezote y el trailer!!",
        icon: "info",
        confirmButtonText: "Aceptar!",
      });
    },

    validarVolumenes() {
      if (this.form.gsv != null) {
        if (this.form.gov > 300 || this.form.gov < 0) {
          this.$swal({
            text:
              "El valor del GOV no puede ser mayor a 300. Favor validar la información!!",
            icon: "warning",
            confirmButtonText: "Aceptar!",
          });
          this.form.gov = null;
        }
        if (this.form.gsv > this.form.gov) {
          this.$swal({
            text:
              "El valor del GOV no puede ser menor al GSV. Favor validar la información!!",
            icon: "warning",
            confirmButtonText: "Aceptar!",
          });
          this.form.gov = null;
          this.form.gsv = null;
        }
        if (this.form.gsv > this.max_barriles) {
          this.$swal({
            text:
              "El valor de GSV no puede superar " +
              this.max_barriles +
              " barriles",
            icon: "warning",
            confirmButtonText: "Aceptar!",
          });
          this.form.gsv = null;
        }
      }

      if (this.form.gsv != null && this.form.gov != null) {
        if (this.form.gsv >= this.form.gov) {
          this.$swal({
            text:
              "El valor de GSV no puede ser mayor o igual al valor de GOV. Favor validar la información!!",
            icon: "warning",
            confirmButtonText: "Aceptar!",
          });
          this.form.gsv = null;
          this.form.gov = null;
        }
      }

      if (this.form.gsv != null && this.form.nsv != null) {
        if (this.form.nsv > this.form.gsv) {
          this.$swal({
            text:
              "El valor de NSV no puede ser mayor al valor de GSV. Favor validar la información!!",
            icon: "warning",
            confirmButtonText: "Aceptar!",
          });
          this.form.nsv = null;
          this.form.gsv = null;
        }
      }
    },

    validarTemp() {
      if (
        this.form.analisis_laboratorio > 250 ||
        this.form.analisis_laboratorio < 50
      ) {
        this.$swal({
          text:
            "La temperatura debe estar entre 50°C y 250°C. Favor validar la información!!",
          icon: "warning",
          confirmButtonText: "Aceptar!",
        });
        this.form.analisis_laboratorio = null;
      }
    },

    validarApi() {
      if (this.form.api > 95 || this.form.api < 6) {
        this.$swal({
          text:
            "El A.P.I debe estar entre 6 y 95. Favor validar la información!!",
          icon: "warning",
          confirmButtonText: "Aceptar!",
        });
        this.form.api = null;
      }
    },

    validarBsw() {
      if (this.form.bsw > 20 || this.form.bsw < 0) {
        this.$swal({
          text:
            "El valor del porcentaje de B.S.W debe estar entre 0 y 20. Favor validar la información!!",
          icon: "warning",
          confirmButtonText: "Aceptar!",
        });
        this.form.bsw = null;
      }
    },

    validarAzufre() {
      if (this.form.azufre > 100 || this.form.azufre < 0) {
        this.$swal({
          text:
            "El valor del porcentaje de Azufre debe estar entre 0 y 100. Favor validar la información!!",
          icon: "warning",
          confirmButtonText: "Aceptar!",
        });
        this.form.azufre = null;
      }
    },

    print() {
      $("#botonImp")
        .attr(
          "href",
          process.env.VUE_APP_API_URL +
            "/toPrint/guiaVentasTerceros/" +
            this.form.id
        )
        .attr("target", "_blank");
      this.estadoGuia = 2;
    },

    back() {
      return this.$router.replace({
        path: `/Hidrocarburos/VentanasOperaciones/${this.sitio_id}/${this.$route.params.sitio_type}/${this.tipo_control}`,
      });
    },
  },

  async mounted() {
    await this.init();
    await this.getTiposVehiculos();
    await this.getTiposProducto();
    await this.getCiudad();
    await this.buscarTransportadora();
    await this.getTipoVh();
    await this.getSitio();
    await this.getProductoLiquido();
    await this.getNovedades();
  },
};
</script>
